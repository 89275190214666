<template>
<!-- name,job_hunting_status,expect_positions,birth,degree,sex,worked_years -->
  <a target="_blank" :href="link"  class="resume-card toLink">
    <div class="resume-card-wrapper">
      <div class="resume-card-left">

        <div class="avatar-box">
          <el-image
            :src="avatorImg"
            lazy
            class="avatar"
            fit="contain"></el-image>
            <div class="name">{{data.name||"王先生"}}</div>
        </div>

        <div class="resume-card-info">
          <div class="resume-tags">
            <!-- <el-tag class="resume-tag" type="info" size="mini">{{data.sex||"性别"}}</el-tag> -->
            <el-tag class="resume-tag" size="small ">{{data.age+"岁"||"年龄"}}</el-tag>
            <el-tag class="resume-tag" size="small ">{{data.degree||"学历"}}</el-tag>
            <el-tag class="resume-tag" size="small ">{{data.worked_years+"年经验"||"工作经验"}}</el-tag>
            <el-tag class="resume-tag" size="small ">{{jobHuntingStatusOptions[data.job_hunting_status-1].label||"该求职者未选择求职状态"}}</el-tag>
          </div>
          <div>
            <el-descriptions class="descriptions" colon>
              <el-descriptions-item label="期望职位" contentClassName="description-item ellipsis"> <span class="description-text highLight">{{data.expect_positions||"暂无期望职位"}}</span> </el-descriptions-item>
              <el-descriptions-item label="期望薪资" contentClassName="description-item ellipsis"> <span class="description-text highLight">{{data.expect_salary||"面议"}}</span> </el-descriptions-item>
              <el-descriptions-item label="工作经历" contentClassName="description-item ellipsis-2"> <span class="description-text">{{data.job_title||"该求职者未填写相关经历"}}</span> </el-descriptions-item>
              <el-descriptions-item label="个人简介" contentClassName="description-item ellipsis-2" :span="2" > <span class="description-text">{{data.summary||"该求职者未填写个人简介"}}</span> </el-descriptions-item>
              <el-descriptions-item label="教育经历" contentClassName="description-item ellipsis-2"> <span class="description-text">{{ data.school_name&&data.major? data.school_name+' / '+data.major : "该求职者未填写相关经历"}}</span> </el-descriptions-item>
            </el-descriptions>
          </div>
        </div>

      </div>

      <div class="resume-card-right">
        <div class="button-container">
          <el-button class="btn" type="primary">登录查看</el-button>
        </div>
        <div class="last-apply-time">最近活跃时间：{{$dayjs(data.last_refresh_time||data.last_apply_time).format("YYYY.MM.DD")}}</div>
      </div>
     
    </div>
  </a>
</template>
<script>
import {jobSalaryType} from "constants/publicData"

export default {
  name:"ResumeCard",
  props:['data','link'],
  data:()=>({}),
  mounted(){
  },
  methods:{
    
  },
  computed:{
    avatorImg(){
      if(this.data.sex==="男"){
        return require('assets/boyImg.jpg')
      }else{
        return require('assets/girlImg.jpg')
      }
    },
    // getTimeGroups(){
    //   if(this.data) return this.$useUtils.processTimeGroup(this.data.start_date,this.data.end_date)
    //   return ""
    // },
    processAge(){
      return this.$dayjs(this.data.birth).diff(this.$dayjs(),'year')
    },
    processNameFirst(){
      let name = String(this.data.name)
      if(this.data.sex==="男"){
        return name[0]+"先生"
      }else{
        return name[0]+"女士"
      }
    },
    
    salary() {
      let salary_min;
      let salary_max;
      let min_key;
      let max_key;
      let up = false
      if (Number(this.data.salary_min) > 999) {
        salary_min = this.data.salary_min / 1000;
        min_key = true;
      }
      if (Number(this.data.salary_max) > 999) {
        if(Number(this.data.salary_max) == 99999){
          up = true
        }
        salary_max = this.data.salary_max / 1000;
        max_key = true;
      }
      if (salary_min && salary_min > 0) {
        if (salary_max && salary_max > 0) {   
              
          if (min_key && max_key) {
            // console.log(salary_min,salary_max);   
            if(up){
              return (salary_min+"K/月 以上 ")
            }else{
              return (
                salary_min + "K-" +
                salary_max + "K"  +
                // jobSalaryType[this.data.salary_unit]||
                "月"
              );
            }
          } else {
            return (
              salary_min +
              "-" +
              salary_max +
              // jobSalaryType[this.data.salary_unit]||
              "月"
            );
          }
        } else {
          if (min_key) {
            return salary_min + "K" + 
            // jobSalaryType[this.data.salary_unit];
            "月"
          }
          return salary_min + 
          jobSalaryType[this.data.salary_unit]+
          "月";
        }
      } else {
        if (salary_max && salary_max > 0) {
          if (max_key) {
            return salary_max + "K" + 
            // jobSalaryType[this.data.salary_unit]
            "月";
          }
          return salary_max + 
          // jobSalaryType[this.data.salary_unit]
          "月";
        }
      }
      return "面议";
    },
    city(){
      if(this.data && this.data.city){
        let cityStr = this.data.city
        if(cityStr){
         let arr = cityStr.split("-")
         return arr[arr.length-1]
        }
      }
      return ''
      
      
    },
    jobHuntingStatusOptions:function(){
      return this.$usePublicData.resumeData.jobHuntingStatusOptions
    }
  },
  watch:{},

}
</script>
<style lang="scss">
.resume-card{
  display: block;
  .resume-card-wrapper{
      width: 100%;
      height: 142px;
      padding: 18px 20px;
      background-color: var(--cardColor,#FFF);
      border: 1px solid var(--borderLineColor,#F4F4F4);
      font-size: var(--little,12px);
      display: flex;
      justify-content: space-between;
      text-align: left;
      &:hover{
        >.resume-card-left>.avatar-box>.name{
          color: var(--primaryColor,#ff9967);
        }
        >.resume-card-right>.button-container>.btn{
          color: #fff;
          background-color: var(--primaryColor,#ff9967);
        }
      }

      .resume-card-left{
        display: flex;
        padding-right: 50px;
        .avatar-box{
          margin-right: 20px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          .avatar{
            width: 80px;
            height: 80px;
            border-radius: 999px;
            border: 1px solid var(--borderLineColor, #f4f4f4);
          }
          .name{
            text-align: center;
            color: var(--titleColor, #333);
            font-size: var(--large, 16px);
            font-weight: 500;
          }
        }

        .resume-card-info{
          display: flex;
          justify-content: center;
          flex-direction: column;

          .resume-tags{
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .resume-tag{
              flex: none;
              margin-right: 12px;
              &.el-tag{
                background-color: var(--primaryColor,#ff9967);
                color: var(--defaultColor,#fff);
                border: var(--primaryColor,#ff9967);
              }
            }
            .city-tag{
              max-width: 160px;
            }
          }

          .descriptions{
            .el-descriptions-item__label{
              flex: none;
              color: var(--titleColor,#333);
            }
            .el-descriptions-item{
              padding-bottom: 12px;
              line-height: 1.2;
              &:nth-of-type(1){
                width: 280px;
              }
              &:nth-of-type(2){
                width: 220px;
              }
            }
            .description-item{
              padding-right: 20px;
            }
            
            .description-text{
              color: var(--cardsubTextColor,#666);
              &.highLight{
                color: var(--primaryColor,#ff9967);
              }
            }
          }

        }
      }

      .resume-card-right{
        text-align: right;
        flex: none;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding-right: 10px;
        .button-container{
          height: 80%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .btn{
            padding:10px 20px;
            border: 1px solid var(--primary,#ff9967);
            color: var(--primary,#ff9967);
            background-color: var(--defaultColor,#fff);
          }
        }
        .last-apply-time{
          color: var(--promptColor,#999);
        }
      }
      
      


  }
}

</style>