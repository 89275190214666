<template>
  <div class="resume-list hasPagination">
    <div class="resume-list-wrapper">
      <div v-for="(l, i) in data" :key="i" class="item" link="#">
        <ResumeCard :data="l" :link="$useRouterLinks.ats"/>
      </div>
    </div>
    <div class="pagination" v-if="nums && pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="page"
        :total="total"
        :page-size="nums"
        :hide-on-single-page="!pagination || !total || total < nums"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import ResumeCard from "components/displayComponents/resumeCard";
import {toJobDetail,toCompanyDetail} from "constants/routerLink"
export default {
  name: "JobListWrapper",
  props: {
    nums: {
      type:Number,
      default:12
    },
    pagination: {
      type:Boolean,
      default:false
    },
    filterData: {
      type: Object,
      default(){
        return {}
      }
    },
    ajaxApi:{
      type:String,
      default:'getPublicResumeList'
    },
    ids:Array,
  },
  data: () => ({
    page: 1,
    total: null,
    data: null,
  }),
  created() {
    this.getData();
  },
   computed: {
    toJobDetail:()=>toJobDetail,
    toCompanyDetail:()=>toCompanyDetail
  },
  methods: {
    handleCurrentChange(page) {
      this.page = page;
      this.getData();
    },
    getData() {
      let data = {...this.filterData}
      let ids=[]
      let params = {
        limit: this.nums,
        offset: (this.page - 1) * this.nums,
      };
      if(this.ids&&this.ids.length){
        ids = this.ids
      }
      if (Object.values(this.filterData).length) {
        delete data.curIndex
        params = Object.assign(params,data);
      }
      // console.log(params);
      this.$axiosRequest({
        name: this.ajaxApi,
        params: params,
        ids:ids
      })
        .then((res) => {
          // console.log(res.data);
          if (res.status === 200) {
            this.data = res.data.results;
            this.total = res.data.count;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
 
  watch: {
    filterData(val, prev) {
      if (val !== prev) {
        this.page = 1;
        this.getData();
      }
    },
  },
  components: {
    ResumeCard,
  },
};
</script>
<style lang="scss">
@import "constants/style/public";
.resume-list {
  .resume-list-wrapper {
    // display: flex;
    flex-wrap: wrap;
    .item {
      // margin-right: 15px;
      margin-bottom: 15px;

      // &:nth-of-type(3n) {
      //   margin-right: 0;
      // }
    }
  }
  .pagination{
    text-align: center;
  }
}

</style>