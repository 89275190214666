<template>
  <div class="jobs page-container">
    <BannerSwiper height="280px" :swiperList="bannerList" fit="cover"/>
    <PersonalFilter :initData="filterData" @change="getFilterData"/>
    <PersonalListWrapper :nums="12" pagination :filterData="filterData" style="margin-top:26px" />
  </div>
</template>
<script>
import BannerSwiper from "components/bannerSwiper";
import PersonalFilter from "components/personal/personalFilter"
import PersonalListWrapper from "components/personal/personalListWrapper"

export default {
  name: "PageJobs",
  data: () => ({
    bannerList:[{
      img_url:'https://cf-des-link-1257093263.cos.ap-shanghai.myqcloud.com/qzrcw%E6%89%BE%E4%BA%BA%E6%89%8D.jpg',
      title:"",
      link:""
    }],
    filterData:{
      curIndex: 0,
      work_experience: "",
      degree: "",
      age:"",
      search_content: "",
    }
  }),
  created(){
    let filterData = {...this.filterData}
    filterData.search_content = this.$route.params.search_content||""
    this.filterData = filterData
  },
  methods: {
    getFilterData(data){
      this.filterData = data
    }
  },
  computed: {
  },
  watch: {},
  components: { BannerSwiper,PersonalFilter,PersonalListWrapper }
};
</script>
<style lang="scss">
// @import "constants/style/public";
</style>