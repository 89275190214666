<template>
  <div class="jobs-filter-wrapper">
    <div class="jobs-filter-card">
      <div class="jobs-filter-list top">
        <div class="jobs-filter-list-left">
          <!-- tab -->
          <div
            :class="'filter-job-cate ' + (curIndex === i ? 'active' : '')"
            v-for="(l, i) in selectKeys"
            :key="i"
            @click="toggleCate(i)"
            v-text="l.label"
          />
        </div>
        <!-- 搜索 -->
        <!-- <div class="search-box">
          <el-input
            placeholder="请输入内容"
            class="search-input"
            prefix-icon="el-icon-search"
            @change="searched"
            v-model="search_content"
            size="small"
          >
          </el-input>
        </div> -->
      </div>

      <!-- 底部 下拉筛选部分 -->
      <div class="jobs-filter-list">
        <div class="jobs-filter-list-left">
          
          <!-- 年龄筛选 -->
          <div class="jobs-filter-item">
            <el-dropdown
              trigger="click"
              @command="handleCommand($event, 'age')"
            >
              <span class="el-dropdown-link">
                年龄要求：<span class="drop-title">
                  {{ age || "不限" }}
                </span>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="l in ages"
                  :command="l.value"
                  :key="l.label"
                  v-text="l.label"
                />
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <!-- 工作经验 -->
          <div class="jobs-filter-item">
            <el-dropdown
            trigger="click"
            @command="handleCommand($event, 'work_experience')"
          >
            <span class="el-dropdown-link">
              工作经验：<span class="drop-title">
                {{ work_experience || "不限" }}
              </span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="l in experienceList"
                :command="l.value"
                :key="l.label"
                v-text="l.label"
              />
            </el-dropdown-menu>
          </el-dropdown>
          </div>
            
          <!-- 学历 -->
          <div class="jobs-filter-item">
            <el-dropdown
              trigger="click"
              @command="handleCommand($event, 'degree')"
            >
              <span class="el-dropdown-link">
                学历要求：<span class="drop-title">
                  {{ degree || "不限" }}
                </span>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="l in educationList"
                  :command="l.value"
                  :key="l.label"
                  v-text="l.label"
                />
              </el-dropdown-menu>
            </el-dropdown>
          </div>
            
          <!-- 薪资 -->
          <!-- <div class="jobs-filter-item">
            <el-dropdown
              trigger="click"
              class="dropdown-box"
              @command="handleCommand($event, 'salary_range')"
            >
              <span class="el-dropdown-link">
                薪资要求：<span class="drop-title">
                  {{ salary_range || "不限" }}
                </span>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="l in salaryList"
                  :command="l.value"
                  :key="l.label"
                  v-text="l.label"
                />
              </el-dropdown-menu>
            </el-dropdown>
          </div> -->
          
        </div>

        <div class="filter-clear" @click="clear">清空筛选条件</div>
      </div>
    </div>
  </div>
</template>
<script>
import { experience, salary, education, personalCate, category, ages} from "constants/publicData";
export default {
  name: "JobsFilter",
  props: {
    initData: Object,
  },
  data() {
    return {
      curIndex: 0,
      work_experience: "",
      degree: "",
      search_content: "",
      selectItmes: {},
      back: {},
      age:""
    };
  },
  created() {
    if (this.initData) {
      this.init(this.initData);
    }
  },
  methods: {
    init(initData) {
      const {
        curIndex,
        work_experience,
        degree,
        age,
        search_content,
      } = initData;

      if (
        curIndex !== 0 &&
        curIndex !== undefined &&
        curIndex !== null &&
        curIndex !== "" &&
        !isNaN(curIndex)
      ) {
        if (typeof curIndex == "number") this.curIndex = curIndex || 0;
        else console.error("initData.curIndex error");
      }
      if (work_experience) {
        if (experience.some((item) => item === work_experience))
          this.work_experience = work_experience || "";
        else console.error("initData.work_experience error");
      }

      if (degree) {
        if (education.some((item) => item === degree))
          this.degree = degree || "";
        else console.error("initData.degree error");
      }
      if(age){
        if (ages.some((item) => item === age))
          this.ages = age || "";
        else console.error("initData.age error");
      }

      // if (salary_range) {
      //   if (salary.some((item) => item === salary_range))
      //     this.salary_range = salary_range || "";
      //   else console.error("initData.curIndex error");
      // }

      if (search_content) {
        this.search_content = search_content || "";
      }

      personalCate.map((item) => {
        if (initData[item.value]) {
          this.selectItmes[item.value] = initData[item.value];
        }
      });
    },

    toggleCate(i) {
      this.curIndex = i;
      if(Object.keys(this.selectItmes).length){
        for(let key in this.selectItmes){
          this.selectItmes[key]=""
        }
      }
      
      if (personalCate[i] !== -1 && personalCate[i].value) {
        this.selectItmes[personalCate[i].value] = true;
      } else if (personalCate[i].value) {
        this.selectItmes[personalCate[i].value] = "";
      }
      this.callBack();
    },

    handleCommand(command, name) {
      this[name] = command;
      this.callBack();
    },

    searched(val) {
      this.search_content = val;
      this.callBack();
    },

    clear() {
      this.experiences = "";
      this.educations = "";
      this.salary_range = "";
      this.age = ""
      this.callBack();
    },

    callBack() {
      let data = {
        curIndex: this.curIndex,
        work_experience: this.work_experience,
        degree: this.degree,
        salary_range: this.salary_range,
        search_content: this.search_content,
        age:this.age,
        ...this.selectItmes
      };
      // console.log(data);
      this.$emit("change", data);
    },
  },
  computed: {
    selectKeys: () => personalCate,
    experienceList: () => experience,
    salaryList: () => salary,
    educationList: () => education,
    category:()=>category,
    ages:()=>ages
  },
  watch: {},
};
</script>
<style lang="scss">
@import "constants/style/public";
.jobs-filter-card {
  background-color: $defaultBg;
  margin-top: 26px;
  padding: 15px 10px;
  box-shadow: 0 0 18px $shadowColor;
  border-radius: $borderRadius;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.jobs-filter-list {
  padding: 20px 13px 0;
  display: flex;
  justify-content: space-between;
  &.top {
    padding: 0;
    padding-bottom: 15px;
    border-bottom: 1px solid $lineColor;
  }
  .jobs-filter-list-left {
    display: flex;
    .jobs-filter-item {
      font-size: var(--medium,14px);
      margin-right: 80px;
      
      .drop-title {
        color: $moreColor;
        margin-right: 20px;
      }
      .el-dropdown-link {
        cursor: pointer;
      }
      .industry-style{
        &.el-cascader{
          line-height: 1;
        }
        .el-input__suffix{
          color: #606266;;
        }
        >.el-input{
          .el-input__inner{
            &::placeholder{
              color: var(--moreColor,#666);
            }
            height: auto;
            line-height: 1;
            border: 0;
            color: var(--moreColor,#666);
          }
          .el-input__icon{
            line-height: 1;
          }
        }
      }
    }
    .filter-job-cate {
      position: relative;
      cursor: pointer;
      margin: 0 13px;
      padding: 7px 23px;
      &.active {
        &::after{
          width: 100%;
          height: 2px;
          content: "";
          position: relative;
          top: 12px;
          background-color: var(--moreColor,#666);
          display: block;
        }
      }
    }
  }
  .search-input {
    .el-input__inner {
      border-radius: $borderRound;
      &:focus {
        border-color: $moreColor;
      }
      // background-color: $searchBg;
    }
  }
  .filter-clear {
    cursor: pointer;
    font-size: $medium;
    color: $moreColor;
  }
}
</style>